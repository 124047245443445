import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

class FormatsMobile {
  constructor (el) {
    this.el = el
    this.panels = el.querySelectorAll('.formats-mobile__block')

    this.initEvents()
  }

  panelToggle (e) {
    const target = e.currentTarget
    const tl = gsap.timeline({
      defaults: {
        duration: 0.6,
        ease: 'expo.inOut'
      }
    })

    if (!target) {
      return
    }

    const short = target.querySelector('.formats-mobile__entry--short')
    const long = target.querySelector('.formats-mobile__entry--long')
    const overlay = target.querySelector('.formats-mobile__overlay')
    const activeAttr = target.getAttribute('data-active')
    target.setAttribute('data-active', activeAttr === '0' ? '1' : '0')
    const isActive = target.getAttribute('data-active') === '1'

    tl.to(isActive ? short : long, {
      autoAlpha: 0,
      y: 30
    })
    tl.to(overlay, {
      paddingBottom: isActive ? '200%' : '106.67%'
    }, '-=0.3')
    tl.to(isActive ? long : short, {
      autoAlpha: 1,
      y: 0
    })
  }

  initEvents () {
    Array.from(this.panels).forEach(el => {
      const clickHandler = e => this.panelToggle(e)
      el.addEventListener('click', clickHandler)
    })
  }
}

docReady(() => {
  const formatsMobileEl = document.querySelector('.formats-mobile')
  if (formatsMobileEl) {
    // eslint-disable-next-line
    const formatsMobile = new FormatsMobile(formatsMobileEl)
  }
})
