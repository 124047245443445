import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'

class Slide {
  constructor (el) {
    this.el = el
    this.header = this.el.querySelector('.featured-formats__header')
    this.short = this.el.querySelector('.featured-formats__short')
    this.shortInner = this.el.querySelector('.featured-formats__short-inner')
    this.overlayImg = this.el.querySelector('.featured-formats__image')

    this.initEvents()
  }

  enterSlide () {
    const tl = gsap.timeline()

    if (this.header) gsap.killTweensOf(this.header)
    if (this.shortInner) gsap.killTweensOf(this.shortInner)
    if (this.short) gsap.killTweensOf(this.short)

    if (this.header) {
      tl.to(this.header, {
        duration: 0.7,
        ease: 'expo.out',
        y: -10
      }, 0)
    }

    if (this.shortInner) {
      tl.to(this.shortInner, {
        duration: 0.7,
        ease: 'expo.out',
        y: 0
      }, 0)
    }

    if (this.short) {
      tl.to(this.short, {
        duration: 1,
        ease: 'expo.out',
        maxHeight: this.short.scrollHeight
      }, 0)
    }

    if (this.overlayImg) {
      tl.to(this.overlayImg, {
        duration: 0.7,
        ease: 'expo.out',
        scale: 1.1
      }, 0)
    }
  }

  leaveSlide (fast = false) {
    const tl = gsap.timeline()

    if (this.header) gsap.killTweensOf(this.header)
    if (this.shortInner) gsap.killTweensOf(this.shortInner)
    if (this.short) gsap.killTweensOf(this.short)
    if (this.overlayImg) gsap.killTweensOf(this.overlayImg)

    if (this.header) {
      tl.to(this.header, {
        duration: fast ? 0.25 : 0.5,
        ease: 'expo.out',
        y: 0,
        clearProps: 'all'
      }, 0)
    }

    if (this.shortInner) {
      tl.to(this.shortInner, {
        duration: fast ? 0.5 : 1,
        ease: 'expo.out',
        y: '100%',
        clearProps: 'all'
      }, 0)
    }

    if (this.short) {
      tl.to(this.short, {
        duration: fast ? 0.25 : 0.5,
        ease: 'expo.out',
        maxHeight: 0,
        clearProps: 'all'
      }, 0)
    }

    if (this.overlayImg) {
      tl.to(this.overlayImg, {
        duration: fast ? 0.15 : 0.3,
        ease: 'expo.out',
        scale: 1,
        clearProps: 'all'
      }, 0)
    }
  }

  initEvents () {
    const mouseEnterHandler = e => this.enterSlide(e)
    this.el.addEventListener('mouseenter', mouseEnterHandler)
    const mouseLeaveHandler = e => this.leaveSlide(e)
    this.el.addEventListener('mouseleave', mouseLeaveHandler)
  }
}

docReady(() => {
  const slides = document.querySelectorAll('.l-drieluik__slide')
  Array.from(slides).forEach(el => {
    // eslint-disable-next-line
    const slide = new Slide(el)
  })
})
