import docReady from '@/js/helpers/doc-ready'
import LocomotiveScroll from 'locomotive-scroll'

let scroll = false

if (document.querySelector('[data-scroll-container]')) {
  scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    direction: 'horizontal',
    // smartphone: {
    //   smooth: true,
    //   direction: 'horizontal',
    //   gestureDirection: 'horizontal'
    // },
    tablet: {
      breakpoint: 767,
      smooth: true,
      direction: 'horizontal',
      gestureDirection: 'horizontal'
    },
    reloadOnContextChange: true
  })
}

docReady(() => {
  if (scroll) {
    scroll.update()
  }
})

export default scroll
