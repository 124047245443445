import docReady from '@/js/helpers/doc-ready'

const observer = new window.IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const el = entry.target
      observer.unobserve(el)

      const num = el.getAttribute('data-num')
      let i = num > 1000 ? num - 200 : Math.ceil(num * 0.75)
      const delay = 3000 / (num - i)

      const inv = setInterval(function () {
        if (i <= num) {
          const newNum = i++
          el.innerHTML = newNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        } else {
          clearInterval(inv)
        }
      }, delay)
    }
  })
})

docReady(() => {
  const counters = document.querySelectorAll('[data-num]')
  if (counters.length) {
    counters.forEach(item => {
      observer.observe(item)
    })
  }
})
