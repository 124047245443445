import docReady from '@/js/helpers/doc-ready'
import Plyr from 'plyr'
import gsap from 'gsap'

docReady(() => {
  const players = document.querySelectorAll('.b__player')
  const togglers = document.querySelectorAll('[data-toggle-video]')

  if (players.length) {
    for (let i = 0; i < players.length; i++) {
      // eslint-disable-next-line
      const player = new Plyr(players[i], {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
        fullscreen: { enabled: true, fallback: true, iosNative: true, container: null }
      })
    }
  }

  Array.from(togglers).forEach(el => {
    el.addEventListener('click', () => {
      const videoRef = el.getAttribute('data-toggle-video')
      const videoEl = document.querySelector(`[data-video="${videoRef}"]`)
      const overlayEl = document.querySelector(`[data-overlay="${videoRef}"]`)

      gsap.to(overlayEl, {
        autoAlpha: 0,
        duration: 1,
        ease: 'expo.out'
      })

      videoEl.play()
    })
  })
})
