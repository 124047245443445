import docReady from '@/js/helpers/doc-ready'
import throttle from '@/js/helpers/throttle'

const spyScrolling = () => {
  const sections = document.querySelectorAll('.l[id]')

  const checkSections = () => {
    const scrollPos = document.documentElement.scrollTop || document.body.scrollTop

    Array.from(sections).forEach(el => {
      const id = el.id
      const active = el.offsetTop <= scrollPos + 10
      const links = document.querySelectorAll(`a[href*=${id}]`)

      if (active) {
        Array.from(document.querySelectorAll('.active')).forEach(item => {
          item.classList.remove('active')
        })
        Array.from(links).forEach(item => {
          item.classList.add('active')
        })
      }
    })
  }

  checkSections()

  window.addEventListener('scroll', throttle(checkSections, 100))
}

docReady(() => {
  spyScrolling()
})
