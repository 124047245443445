import { scrollToY } from '@/js/helpers/scroll'
import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const links = document.querySelectorAll('a[href*="#"]:not([href="#"])')
  const nextBlockButtons = document.querySelectorAll('.l-video-hero__scroll')

  if (links.length && window.innerWidth > 768) {
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', function (e) {
        const target = this.hash
        const targetEl = document.getElementById(target.slice(1))

        if (targetEl) {
          const rect = targetEl.getBoundingClientRect()
          const offset = rect.top
          const bodyAmount = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

          scrollToY(offset + bodyAmount, 1000)
        }
      })
    }
  }

  const wh = window.innerHeight

  Array.from(nextBlockButtons).forEach(el => {
    el.addEventListener('click', () => {
      scrollToY(wh, 1000)
    })
  })
})
