import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.watch-slider')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      watchCSS: true,
      autoPlay: 3000,
      draggable: false,
      accessibility: false
    })
  })
})
