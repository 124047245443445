import docReady from '@/js/helpers/doc-ready'
import scroll from '@/js/components/scroll'
import throttle from '@/js/helpers/throttle'
import gsap from 'gsap'

class Glider {
  constructor (el) {
    this.el = el
    this.scrollContainer = this.el.querySelector('[data-scroll-container]')
    this.arrowButtons = this.el.querySelectorAll('[data-glide-dir]')
    this.track = this.el.querySelector('[data-glide-el="track"]')
    this.summaries = this.el.querySelectorAll('.featured-formats__summary')
    this.fulls = this.el.querySelectorAll('.featured-formats__full')
    this.overlays = this.el.querySelectorAll('.featured-formats__overlay')

    // Navigation
    this.baseNavigation = this.el.querySelector('.featured-formats__arrows--base')
    this.altNavigation = this.el.querySelector('.featured-formats__arrows--alt')
    this.leftArrows = this.el.querySelectorAll('.glide__arrow--left')
    this.rightArrows = this.el.querySelectorAll('.glide__arrow--right')

    // Slides
    this.slides = this.el.querySelectorAll('[data-scroll-id]')
    this.firstSlide = this.slides[0]
    this.isThirdToLastSlide = this.slides[this.slides.length - 3]
    this.isSecondToLastSlide = this.slides[this.slides.length - 2]
    this.lastSlide = this.slides[this.slides.length - 1]
    this.firstSlideInView = this.slides[0]

    // Options
    this.fullscreenActive = false
    this.allFull = false
    this.currentIndex = 0
    this.scrollOptions = {
      duration: 1000,
      easing: [0.19, 1, 0.22, 1]
    }

    this.initEvents()
  }

  arrowButtonClick (e) {
    const target = e.currentTarget

    if (!target) {
      return
    }

    const movement = target.getAttribute('data-glide-dir')
    const currentIndex = +this.firstSlideInView.getAttribute('data-scroll-id')
    let nextSlideToShow = false

    switch (movement) {
      case '|<': {
        const previousIndex = currentIndex - 3
        nextSlideToShow = this.el.querySelector(`[data-scroll-id="${previousIndex}"]`)
        if (!nextSlideToShow) {
          nextSlideToShow = this.firstSlide
        }
        break
      }
      case '|>': {
        const nextIndex = currentIndex + 3
        nextSlideToShow = this.el.querySelector(`[data-scroll-id="${nextIndex}"]`)
        if (!nextSlideToShow) {
          nextSlideToShow = this.lastSlide
        }
        break
      }
      case '>': {
        const nextIndex = currentIndex + 1
        nextSlideToShow = this.el.querySelector(`[data-scroll-id="${nextIndex}"]`)
        if (!nextSlideToShow) {
          nextSlideToShow = this.lastSlide
        }
        break
      }
      case '<': {
        const previousIndex = currentIndex - 1
        nextSlideToShow = this.el.querySelector(`[data-scroll-id="${previousIndex}"]`)
        if (!nextSlideToShow) {
          nextSlideToShow = this.firstSlide
        }
        break
      } default:
        console.log('default: something went wrong')
    }

    if (nextSlideToShow) {
      const nextActiveFull = nextSlideToShow.querySelector('.featured-formats__full')
      if (this.fullscreenActive) {
        const currentActiveFull = this.firstSlideInView.querySelector('.featured-formats__full')
        const currentActiveImage = this.firstSlideInView.querySelector('.featured-formats__overlay')
        const nextActiveImage = nextSlideToShow.querySelector('.featured-formats__overlay')
        const goingRight = +this.firstSlideInView.getAttribute('data-scroll-id') > +nextSlideToShow.getAttribute('data-scroll-id')

        gsap.set(nextActiveFull, {
          autoAlpha: 0,
          x: -100
        })
        gsap.to(currentActiveFull, {
          ease: 'expo.out',
          duration: 1,
          autoAlpha: 0,
          x: -100,
          delay: 0.5
        })

        gsap.fromTo(nextActiveImage, {
          x: goingRight ? '20%' : '-20%'
        }, {
          x: 0,
          duration: 1.3,
          ease: 'circ.inOut'
        })
        gsap.to(currentActiveImage, {
          x: goingRight ? '-20%' : '20%',
          duration: 1.3,
          ease: 'circ.inOut'
        })
      }

      scroll.scrollTo(nextSlideToShow, {
        duration: this.fullscreenActive ? 1300 : 1800,
        easing: this.fullscreenActive ? [0.785, 0.135, 0.15, 0.86] : [0.19, 1, 0.22, 1],
        callback: () => {
          if (this.fullscreenActive) {
            gsap.to(nextActiveFull, {
              ease: 'expo.out',
              duration: 1,
              autoAlpha: 1,
              x: 0
            })
          }
        }
      })

      scroll.update()
    }
  }

  switchNavBars () {
    const tl = gsap.timeline({ defaults: { duration: 0.4, ease: 'power3.out' } })
    if (this.baseNavigation) {
      tl.to(this.baseNavigation, { autoAlpha: 0 })
    }
    tl.to(this.altNavigation, { autoAlpha: 1 })
  }

  checkNavigation () {
    Array.from(this.leftArrows).forEach(el => {
      el.classList.toggle('glide__arrow--disabled', this.firstSlideInView === this.firstSlide)
    })
    Array.from(this.rightArrows).forEach(el => {
      el.classList.toggle('glide__arrow--disabled', this.fullscreenActive ? this.firstSlideInView === this.lastSlide : this.firstSlideInView === this.isThirdToLastSlide)
    })
  }

  openSlide (slide) {
    this.fullscreenActive = true

    // If it's already active, don't continue
    if (slide.classList.contains('featured-formats__slide--active')) {
      return
    }

    const summary = slide.querySelector('.featured-formats__summary')
    const overlayImg = slide.querySelector('.featured-formats__image')
    const full = slide.querySelector('.featured-formats__full')
    const tl = gsap.timeline()

    // Set active slide to front, other slides to back
    gsap.set(this.slides, { zIndex: 1 })
    gsap.set(slide, { zIndex: 3 })

    if (summary) {
      gsap.killTweensOf(summary)
    }

    if (full) {
      gsap.killTweensOf(full)
    }

    this.minimizeSlide(slide, true)

    setTimeout(() => {
      if (summary) {
        gsap.to(summary, { duration: 0.2, ease: 'circ.out', autoAlpha: 0, y: 150 })
      }
      if (overlayImg) {
        gsap.to(overlayImg, { duration: 0.4, ease: 'expo.out', scale: 1 })
      }

      // Scroll to active slide
      scroll.scrollTo(slide, this.scrollOptions)

      // Switch navigation bars
      this.switchNavBars()

      // If clicked slide is the final slide, add padding
      if (slide === this.lastSlide || slide === this.isSecondToLastSlide) {
        const paddingRight = slide === this.lastSlide ? '66.666vw' : '33.333vw'
        gsap.set(this.track, { paddingRight })
        scroll.update()
        scroll.scrollTo(slide, this.scrollOptions)
      }

      // Set active slide width first
      gsap.to(slide, {
        duration: 0.7,
        ease: 'expo.out',
        width: '100vw',
        onStart: () => {
          scroll.scrollTo(slide, {
            duration: 700,
            easing: [0.19, 1, 0.22, 1],
            disableLerp: true
          })

          scroll.update()
        },
        onComplete: () => {
          scroll.stop()
          scroll.update()
          if (!this.allFull) {
            this.allFull = true

            gsap.set(this.slides, { width: '100vw', autoAlpha: 0 })
            gsap.set(this.summaries, { autoAlpha: 0 })
            scroll.update()
            gsap.set(slide, { autoAlpha: 1 })
            gsap.set(this.track, { clearProps: 'all' })
            scroll.update()

            scroll.scrollTo(slide, {
              duration: 0,
              disableLerp: true,
              callback: () => {
                gsap.set(this.slides, { autoAlpha: 1, delay: 0.5 })
                scroll.update()
              }
            })

            tl.fromTo(full, {
              autoAlpha: 0,
              x: -100
            }, {
              ease: 'expo.out',
              duration: 1,
              autoAlpha: 1,
              x: 0
            }, 0.1)
          }
        }
      })
    }, 500)
  }

  enterSlide (e) {
    if (this.fullscreenActive) {
      return
    }

    const slide = e.currentTarget

    const tl = gsap.timeline()
    const short = slide.querySelector('.featured-formats__short')
    const shortInner = slide.querySelector('.featured-formats__short-inner')
    const header = slide.querySelector('.featured-formats__header')
    const overlayImg = slide.querySelector('.featured-formats__image')

    if (header) {
      gsap.killTweensOf(header)

      tl.to(header, {
        duration: 1,
        ease: 'expo.out',
        y: -10
      }, 0)
    }
    if (shortInner) {
      gsap.killTweensOf(shortInner)

      tl.to(shortInner, {
        duration: 1,
        ease: 'expo.out',
        y: 0
      }, 0)
    }
    if (short) {
      gsap.killTweensOf(short)

      tl.to(short, {
        duration: 1.3,
        ease: 'expo.out',
        maxHeight: short.scrollHeight
      }, 0)
    }

    if (overlayImg) {
      tl.to(overlayImg, {
        duration: 1.3,
        ease: 'expo.out',
        scale: 1.1
      }, 0)
    }
  }

  leaveSlide (e) {
    if (this.fullscreenActive) {
      return
    }

    const slide = e.currentTarget
    this.minimizeSlide(slide)
  }

  minimizeSlide (slide, fast = false) {
    const tl = gsap.timeline()
    const short = slide.querySelector('.featured-formats__short')
    const shortInner = slide.querySelector('.featured-formats__short-inner')
    const header = slide.querySelector('.featured-formats__header')
    const overlayImg = slide.querySelector('.featured-formats__image')

    if (header) {
      gsap.killTweensOf(header)

      tl.to(header, {
        duration: fast ? 0.25 : 0.5,
        ease: 'expo.out',
        y: 0,
        clearProps: 'all'
      }, 0)
    }
    if (shortInner) {
      gsap.killTweensOf(shortInner)

      tl.to(shortInner, {
        duration: fast ? 0.5 : 1,
        ease: 'expo.out',
        y: '100%',
        clearProps: 'all'
      }, 0)
    }
    if (short) {
      gsap.killTweensOf(short)

      tl.to(short, {
        duration: fast ? 0.25 : 0.5,
        ease: 'expo.out',
        maxHeight: 0,
        clearProps: 'all'
      }, 0)
    }
    if (overlayImg) {
      gsap.killTweensOf(overlayImg)

      tl.to(overlayImg, {
        duration: fast ? 0.5 : 1,
        ease: 'expo.out',
        scale: 1,
        clearProps: 'all'
      }, 0)
    }
  }

  initEvents () {
    Array.from(this.arrowButtons).forEach(el => {
      const clickHandler = e => this.arrowButtonClick(e)
      el.addEventListener('click', clickHandler)
    })

    scroll.on('scroll', throttle(args => {
      if (args.currentElements) {
        const filtered = []
        const progressCheck = this.fullscreenActive ? 0.75 : 0.99

        for (const key in args.currentElements) {
          if (args.currentElements[key] && args.currentElements[key].progress < progressCheck) {
            filtered.push(args.currentElements[key])
          }
        }

        if (filtered.length) {
          this.firstSlideInView = filtered[0].el
        }
      }

      this.checkNavigation()
    }, 200))

    Array.from(this.slides).forEach(el => {
      el.addEventListener('click', this.openSlide.bind(this, el))
      const mouseEnterHandler = e => this.enterSlide(e)
      el.addEventListener('mouseenter', mouseEnterHandler)
      const mouseLeaveHandler = e => this.leaveSlide(e)
      el.addEventListener('mouseleave', mouseLeaveHandler)
    })

    window.addEventListener('resize', () => {
      if (this.fullscreenActive && this.firstSlideInView) {
        scroll.scrollTo(this.firstSlideInView, {
          duration: 0,
          disableLerp: true
        })
      }
    })
  }
}

docReady(() => {
  const gliderEl = document.getElementById('glider')
  if (gliderEl) {
    // eslint-disable-next-line
    const glider = new Glider(document.getElementById('glider'))
  }
})
