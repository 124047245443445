// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/animsition'
import '@/js/components/navigation'
import '@/js/components/splash'
import '@/js/components/vh'
import '@/js/components/counter'
import '@/js/components/sliders'
import '@/js/components/map'
import '@/js/components/qa'
import '@/js/components/scroll'
import '@/js/components/formats-slider'
import '@/js/components/ajax'
import '@/js/components/drieluik'
import '@/js/components/hash'
import '@/js/components/plyr'
import '@/js/components/animate'
import '@/js/components/spy'
import '@/js/components/formats-mobile'
